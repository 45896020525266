<template>
  <v-container>
    <v-form ref="form">
      <h3>{{ $t('wizard.insuredPersonForm') }}</h3>
      <v-text-field
        v-model="formData.name"
        :label="$t('wizard.name') + '*'"
        clearable
        :error-messages="nameErrors"
        @blur="$v.formData.name.$touch()"
        @input="$v.formData.name.$touch()"
      >
      </v-text-field>
      <v-menu
        v-model="datepicker_show"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formData.birthDate"
            :label="$t('wizard.birthDate') + '*'"
            :error-messages="birthDateErrors"
            :rules="dateRule"
            v-on="on"
            dense
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="formData.birthDate"
          @input="datepicker_show = false"
        >
        </v-date-picker>
      </v-menu>
      <v-text-field
        v-model="formData.phone"
        :label="$t('wizard.phone')"
        clearable
      ></v-text-field>
      <v-text-field
        v-model="formData.relativeName"
        :label="$t('wizard.relativeName')"
        clearable
      >
      </v-text-field>
      <v-text-field
        v-model="formData.relativePhone"
        :label="$t('wizard.relativePhone')"
        clearable
      ></v-text-field>
      <v-divider></v-divider>
      <v-btn
        color="success"
        class="ma-4"
        @click="saveInsuredPerson"
        :disabled="nameErrors.length > 0 || birthDateErrors.length > 0"
        >{{ $t('buttons.save') }}</v-btn
      >
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')">{{
        $t('buttons.cancel')
      }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
//TODO: validations for date, etc.
import { required } from 'vuelidate/lib/validators'
import { countries } from '../utils/countries'
export default {
  data: function () {
    return {
      countries: countries,
      datepicker_show: false,
      dateRule: [
        (value) =>
          /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value) ||
          this.$i18n.t('wizard.dateFormatValidation'),
      ],
      formData: {
        name: '',
        birthDate: '',
        phone: '',
        relativeName: '',
        relativePhone: '',
      },
    }
  },
  validations: {
    formData: {
      name: {
        required: required,
      },
      birthDate: {
        required: required,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.formData.name.$dirty) return errors
      !this.$v.formData.name.required &&
        errors.push(this.$i18n.t('validations.nameRequired'))
      return errors
    },
    birthDateErrors() {
      const errors = []
      if (!this.$v.formData.birthDate.$dirty) return errors
      !this.$v.formData.birthDate.required &&
        errors.push(this.$i18n.t('validations.dateRequired'))
      return errors
    },
  },
  methods: {
    saveInsuredPerson() {
      // this.$v.formData.name.$touch()
      if (
        !this.$v.$anyError &&
        (this.formData.name !== '' || this.formData.birthDate !== '')
      ) {
        this.$emit('saveInsuredPerson', this.formData)
        this.$refs.form.reset()
      }
    },
  },
}

//TODO: fix $touch bug
</script>

<style lang="scss" scoped></style>
